import { storeToRefs } from "pinia";
import { useOnBoardingStore } from "@/stores/onboarding";
import { useMerchantStore } from "@/stores/merchant";

export default defineNuxtRouteMiddleware((to) => {
  const { account_detail, bank_detail, business_detail } =
    storeToRefs(useOnBoardingStore()); // make authenticated state reactive
  const { profile } = storeToRefs(useMerchantStore()); // make authenticated state reactive
  // checking step 1 done
  const isAccountEmpty =
    !account_detail.value.phone ||
    !account_detail.value.nik ||
    !account_detail.value.ktp?.url;

  const isBankEmpty =
    !bank_detail.value.bank_name ||
    !bank_detail.value.account_number ||
    !bank_detail.value.name_of_account_holder;
  const isBusinessEmpty =
    (profile.value.business_type === "company" &&
      !business_detail.value.company_name) ||
    !business_detail.value.brand_name;

  // Handle if user has done
  // if (profile.value.kyc_status !== "draft") {
  //   abortNavigation();
  //   return navigateTo("/");
  // }

  // handle if the user opens another step without completing step 1
  if (
    (to?.name === "onboarding-step-2" && isAccountEmpty) ||
    (to?.name === "onboarding-step-3" && isAccountEmpty) ||
    (to?.name === "onboarding-step-4" && isAccountEmpty)
  ) {
    abortNavigation();
    return navigateTo("/onboarding/step-1/");
  }
  // Handle if the user opens another step without completing step 2
  else if (
    (to?.name === "onboarding-step-3" && !isAccountEmpty && isBankEmpty) ||
    (to?.name === "onboarding-step-4" && !isAccountEmpty && isBankEmpty)
  ) {
    abortNavigation();
    return navigateTo("/onboarding/step-2/");
  }
  // Handle if the user opens another step without completing step 3
  else if (
    to?.name === "onboarding-step-4" &&
    !isAccountEmpty &&
    !isBankEmpty &&
    isBusinessEmpty
  ) {
    abortNavigation();
    return navigateTo("/onboarding/step-3/");
  }
});
